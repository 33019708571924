import {
  WppButton,
  WppCheckbox,
  WppDatepicker,
  WppIconAddCircle,
  WppIconDrag,
  WppIconTrash,
  WppInput,
  WppModal,
  WppTextareaInput,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { Reorder } from 'framer-motion'
import { useEffect, useState } from 'react'

import { updateBenefitAPI } from './helpers/api-helpers'
import styles from './modals.module.scss'
import { FeatureOrProduct, ObjectType } from '../../api/benefits/fetchers/editBenefit'
import { useEditBenefit } from '../../api/benefits/mutations/useEditBenefit'
import { queryClient } from '../../app/Root'
import { ApiQueryKeys } from '../../constants/apiQueryKeys'
import { useToast } from '../../hooks/useToast'
import { Check, ItemDetails, Task, TimeObject } from '../gantt/src/types/public-types'

export interface AddItemProps {
  open: boolean
  setOpen: (open: boolean) => void
  itemName: string
  benefit: Task
  item?: TimeObject
}

interface Item extends ItemDetails {
  id?: string
  name: string
  start?: Date
  end?: Date
}

export const AddItemModal: React.FC<AddItemProps> = ({ open, setOpen, itemName, benefit, item }) => {
  const [form, setForm] = useState<Item>(
    item ?? {
      name: '',
    },
  )
  const [newCheck, setNewCheck] = useState<Check>({
    name: '',
    check: false,
    id: form.checks?.length.toString() ?? '0',
  })

  useEffect(() => {
    if (item) {
      setForm(item)
    } else {
      setForm({
        name: '',
      })
    }
  }, [item])

  const { showToast } = useToast()
  const { mutateAsync: editBenefit } = useEditBenefit()

  const handleSave = async () => {
    if (!benefit.id) return
    if (!form.start || !form.end || !form.description) {
      showToast({
        type: 'error',
        message: 'Please fill all the fields.',
      })
      return
    }

    let newObject = form as unknown as FeatureOrProduct
    newObject.objectType = itemName === 'Feature' ? ObjectType.feature : ObjectType.product
    newObject.checks = form.checks ? form.checks : []

    const errorMessage = `Couldn't ${form.id ? 'create' : 'update'} ${itemName}. Check console for details.`
    const successMessage = `${itemName} ${form.id ? 'created' : 'updated'} successfully.`
    await updateBenefitAPI({
      editBenefit,
      benefitId: benefit.id,
      newBenefit: benefit,
      newFeatureOrProduct: newObject,
      newFeatureOrProductId: form.id,
      errorMessage,
      successMessage,
      showToast,
    })
    await queryClient.invalidateQueries([ApiQueryKeys.ALL_BENEFITS])
    setOpen(false)
  }

  const handleAddCheck = () => {
    if (newCheck.name === '') return

    const newArray = [...(form.checks ? form.checks : []), newCheck]
    setForm(prevState => ({
      ...prevState,
      checks: newArray,
    }))
    setNewCheck({
      name: '',
      check: false,
      id: newArray.length.toString() ?? '0',
    })
  }

  const handleDeleteCheck = (id: string) => {
    setForm(prevState => ({
      ...prevState,
      checks: form.checks?.filter(check => check.id !== id),
    }))
  }

  const handleChange = (value: Item) => {
    setForm(value)
  }

  const checklistItem = (item: Check) => {
    return (
      <div className={styles.checklistItem}>
        {item.id !== newCheck.id ? (
          <WppIconDrag height={20} width={20} className={styles.dragIcon} />
        ) : (
          <div style={{ width: '28px' }} />
        )}
        <WppCheckbox
          checked={item.check}
          labelConfig={{ text: 'Done' }}
          required
          onWppChange={({ detail: { checked } }) => {
            return item.id !== newCheck.id
              ? handleChange({
                  ...form,
                  checks: form.checks?.map(check => (check.id === item.id ? check : { ...check, checked })),
                })
              : setNewCheck({
                  ...newCheck,
                  check: checked,
                })
          }}
        />
        <WppInput
          placeholder="Checklist item name here"
          value={item.name}
          required
          className={styles.width100}
          onWppChange={e =>
            item.id !== newCheck.id
              ? handleChange({
                  ...form,
                  checks: form.checks?.map(check =>
                    check.id === item.id ? check : { ...check, name: e.target.value },
                  ),
                })
              : setNewCheck({
                  ...newCheck,
                  name: e.target.value,
                })
          }
        />
        {item.id === newCheck.id ? (
          <div className={styles.addIcon}>
            <WppIconAddCircle
              height={20}
              width={20}
              onClick={handleAddCheck}
              className={newCheck.name !== '' ? styles.blink : undefined}
            />
          </div>
        ) : (
          <WppIconTrash
            height={20}
            width={20}
            onClick={() => handleDeleteCheck(item.id)}
            className={styles.deleteIcon}
          />
        )}
      </div>
    )
  }

  return (
    <WppModal
      open={open}
      size="m"
      className={styles.modal}
      onWppModalClose={() => setOpen(false)}
      onWppModalOpen={() => setOpen(true)}
    >
      <h3 slot="header">
        {item ? 'Edit' : 'Add'} {itemName}
      </h3>
      <div slot="body">
        <WppTypography type="s-strong" tag="h5" className={styles.block}>
          Benefit
        </WppTypography>
        <WppTypography type="s-body" tag="span" className={styles.block}>
          {benefit.name}
        </WppTypography>
        <form>
          <WppInput
            className={styles.name}
            name={`${itemName} name`}
            labelConfig={{ text: `${itemName} name` }}
            placeholder={`${itemName} name here`}
            value={form?.name}
            onWppChange={e =>
              handleChange({
                ...form,
                name: e.target.value,
              })
            }
            required
          />
          <WppTextareaInput
            className={styles.name}
            name={`${itemName} description`}
            placeholder="Description here"
            value={form?.description}
            labelConfig={{
              text: 'Description',
            }}
            warningThreshold={1000}
            charactersLimit={1200}
            onWppChange={e =>
              handleChange({
                ...form,
                description: e.target.value,
              })
            }
            required
          />
          <div className={styles.dates}>
            <WppDatepicker
              key={form?.start?.toDateString() + 'start'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
              name="date from"
              labelConfig={{ text: 'Date From' }}
              className={styles.datepicker}
              value={
                form.start?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''
              }
              onWppChange={e =>
                handleChange({
                  ...form,
                  start: e.detail.date as Date,
                })
              }
              required
            />
            <WppDatepicker
              key={form?.end?.toDateString() + 'end'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
              name="date to"
              labelConfig={{ text: 'Date To' }}
              className={styles.datepicker}
              value={form.end?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
              onWppChange={e =>
                handleChange({
                  ...form,
                  end: e.detail.date as Date,
                })
              }
              required
            />
          </div>
          <WppTypography type="s-strong" tag="h5" className={styles.checklistTitle}>
            {itemName} Checklist
          </WppTypography>
          <div className={styles.checklist}>
            <Reorder.Group
              axis="y"
              values={form?.checks ?? []}
              onReorder={newOrder =>
                handleChange({
                  ...form,
                  checks: newOrder,
                })
              }
            >
              {form?.checks?.map((item, i) => (
                <Reorder.Item drag="y" key={i} value={item}>
                  {checklistItem(item)}
                </Reorder.Item>
              ))}
            </Reorder.Group>
            {checklistItem(newCheck)}
          </div>
        </form>
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton variant="secondary" size="s" onClick={() => setOpen(false)}>
          Cancel
        </WppButton>
        <WppButton variant="primary" size="s" onClick={handleSave}>
          Save
        </WppButton>
      </div>
    </WppModal>
  )
}
