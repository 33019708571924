import { WppTooltip } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from './milestone.module.scss'
import { defaultTheme } from '../../gantt/gantt'

export interface ParityMilestoneProps {
  x: number
  y: number
  height: number
  text: string
}

export const ParityMilestone: React.FC<ParityMilestoneProps> = ({ x, y, height, text }) => {
  return (
    <g tabIndex={0} className={styles.milestoneWrapper}>
      <polygon fill={defaultTheme.milestoneParityColor} points={`${x - 5},${y} ${x + 9.5},${y} ${x + 2.5},${y + 10}`} />
      <rect
        fill={defaultTheme.milestoneParityColor}
        x={x}
        width={5}
        y={y}
        height={height}
        className={styles.milestoneBackground}
      />
      <polygon
        fill={defaultTheme.milestoneParityColor}
        points={`${x - 5},${y + height} ${x + 9.5},${y + height} ${x + 2.5},${y - 10 + height}`}
      />
      <foreignObject x={x - 2} width={9} y={y} height={height} style={{ zIndex: 1000 }}>
        <WppTooltip value={text}>
          <div style={{ width: '9px', height: `${height}px` }} />
        </WppTooltip>
      </foreignObject>
    </g>
  )
}
