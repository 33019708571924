import { WppTooltip } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from './milestone.module.scss'
import { defaultTheme } from '../../gantt/gantt'

export interface MilestoneProps {
  x: number
  y: number
  height: number
  text: string
}

export const Milestone: React.FC<MilestoneProps> = ({ x, y, height, text }) => {
  const transform = `rotate(45 ${x + height * 0.356} 
    ${y + height * 0.85})`

  return (
    <g tabIndex={0} className={styles.milestoneWrapper}>
      <rect
        fill={defaultTheme.milestoneColor}
        x={x}
        width={height}
        y={y}
        height={height}
        transform={transform}
        className={styles.milestoneBackground}
      />
      <foreignObject x={x} width={height} y={y} height={height} transform={transform} style={{ zIndex: 1000 }}>
        <WppTooltip value={text}>
          <div style={{ width: '14px', height: '14px' }} />
        </WppTooltip>
      </foreignObject>
    </g>
  )
}
