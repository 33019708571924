import { WppDatepicker, WppIconAddCircle, WppIconTrash, WppInput } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { TimeObject } from 'components/gantt/src/types/public-types'

import { useToast } from '../../../hooks/useToast'
import styles from '../modals.module.scss'

interface ItemProps {
  items: TimeObject[]
  name: string
  handleChange: (value: TimeObject[], key: string) => void
}

export interface NewItem {
  name: string
  start?: Date
  end?: Date
}

export const Item: React.FC<ItemProps> = ({ items, name, handleChange }) => {
  const label = name === 'convergenMilestone' ? 'Convergence' : name === 'adoptionMilestone' ? 'Adoption' : 'Migration'
  const [newItem, setNewItem] = useState<NewItem>({ name: '' })
  const { showToast } = useToast()

  const handleAdd = () => {
    if (!newItem.name || !newItem.start || !newItem.end) {
      showToast({ message: 'Please fill all fields', type: 'error' })
      return
    }
    handleChange(items.concat(newItem as TimeObject), name)
    setNewItem({ name: '' })
  }

  const handleDelete = (index: number) => {
    handleChange(
      items.filter((a, i) => i !== index),
      name,
    )
  }

  const handleChan = (index: number, newValue: any, key: string, itemFor?: TimeObject) => {
    if (itemFor)
      handleChange(
        items.map((a, i) =>
          i !== index
            ? a
            : {
                ...a,
                [key]: newValue,
              },
        ),
        name,
      )
    else {
      setNewItem(prevState => ({
        ...prevState,
        [key]: newValue,
      }))
    }
  }

  const itemElement = (index: number, itemFor?: TimeObject) => {
    const item = index === -1 ? newItem : itemFor
    return (
      <div className={styles.checklistItem}>
        <WppInput
          name={item?.name}
          placeholder={`${label} name here`}
          value={item?.name}
          onWppChange={e => handleChan(index, e.target.value, 'name', itemFor)}
          required
        />
        <WppDatepicker
          key={item?.start?.toDateString() + 'start'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
          className={styles.datepicker}
          value={item?.start?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
          onWppChange={e => handleChan(index, e.detail.date as Date, 'start', itemFor)}
          required
        />
        <WppDatepicker
          key={item?.end?.toDateString() + 'end'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
          className={styles.datepicker}
          value={item?.end?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
          onWppChange={e => {
            handleChan(index, e.detail.date as Date, 'end', itemFor)
          }}
          required
        />
        {index !== -1 ? (
          <div className={styles.delete}>
            <WppIconTrash height={20} width={20} onClick={() => handleDelete(index)} className={styles.deleteIcon} />
          </div>
        ) : (
          <div className={styles.addIcon}>
            <WppIconAddCircle
              height={20}
              width={20}
              onClick={handleAdd}
              className={newItem.name !== '' || newItem.start || newItem.end ? styles.blink : undefined}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.item}>
      {items.map((itemFor, index) => (
        <div key={index}>{itemElement(index, itemFor)}</div>
      ))}
      {itemElement(-1)}
    </div>
  )
}
